import data from "../lib/data";
import moment from "moment";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import { isEmpty, saveBlobAsDownload, debounce } from "../lib/utils";
import Resources from "../lib/resources";
import _ from 'lodash';

export const actions = {
  ...GeneralActions,

  SET_WITH_COMPANY_ID: "SET_WITH_COMPANY_ID",

  FETCHING_ALL_INVOICES: "FETCHING_ALL_INVOICES",
  FETCHED_ALL_INVOICES: "FETCHED_ALL_INVOICES",
  FETCH_ALL_INVOICES_FAILED: "FETCH_ALL_INVOICES_FAILED",

  FETCHING_CLOSED_INVOICES: "FETCHING_CLOSED_INVOICES",
  FETCHED_CLOSED_INVOICES: "FETCHED_CLOSED_INVOICES",
  FETCH_CLOSED_INVOICES_FAILED: "FETCH_CLOSED_INVOICES_FAILED",

  FETCHING_CLOSED_INVOICES_TEMPLATE: "FETCHING_CLOSED_INVOICES_TEMPLATE",
  FETCHED_CLOSED_INVOICES_TEMPLATE: "FETCHED_CLOSED_INVOICES_TEMPLATE",
  FETCH_CLOSED_INVOICES_TEMPLATE_FAILED: "FETCH_CLOSED_INVOICES_TEMPLATE_FAILED",

  UPDATE_CLOSED_INVOICES_SORT: "UPDATE_CLOSED_INVOICES_SORT",
  UPDATE_CLOSED_INVOICES_DATE: "UPDATE_CLOSED_INVOICES_DATE",

  FETCHING_OPEN_INVOICES: "FETCHING_OPEN_INVOICES",
  FETCHED_OPEN_INVOICES: "FETCHED_OPEN_INVOICES",
  FETCH_OPEN_INVOICES_FAILED: "FETCH_OPEN_INVOICES_FAILED",

  FETCHING_OPEN_INVOICES_TEMPLATE: "FETCHING_OPEN_INVOICES_TEMPLATE",
  FETCHED_OPEN_INVOICES_TEMPLATE: "FETCHED_OPEN_INVOICES_TEMPLATE",
  FETCH_OPEN_INVOICES_TEMPLATE_FAILED: "FETCH_OPEN_INVOICES_TEMPLATE_FAILED",

  UPDATE_OPEN_INVOICES_SORT: "UPDATE_OPEN_INVOICES_SORT",
  UPDATE_OPEN_INVOICES_DATE: "UPDATE_OPEN_INVOICES_DATE",
  UPDATE_OPEN_INVOICES_UPPER_TABLE_SORT: "UPDATE_OPEN_INVOICES_UPPER_TABLE_SORT",

  FETCHING_PAYMENTS: "FETCHING_PAYMENTS",
  FETCHED_PAYMENTS: "FETCHED_PAYMENTS",
  FETCH_PAYMENTS_FAILED: "FETCH_PAYMENTS_FAILED",

  FETCHING_UNAPPLIED_PAYMENTS: "FETCHING_UNAPPLIED_PAYMENTS",
  FETCHED_UNAPPLIED_PAYMENTS: "FETCHED_UNAPPLIED_PAYMENTS",
  FETCH_UNAPPLIED_PAYMENTS_FAILED: "FETCH_UNAPPLIED_PAYMENTS_FAILED",
  UPDATE_UNAPPLIED_PAYMENTS_SORT: "UPDATE_UNAPPLIED_PAYMENTS_SORT",

  UPDATE_PAYMENTS_SORT: "UPDATE_PAYMENTS_SORT",
  UPDATE_PAYMENTS_DATE: "UPDATE_PAYMENTS_DATE",

  FETCHING_PAYMENT_INFO: "FETCHING_PAYMENT_INFO",
  FETCHED_PAYMENT_INFO: "FETCHED_PAYMENT_INFO",
  FETCH_PAYMENT_INFO_FAILED: "FETCH_PAYMENT_INFO_FAILED",

  MAKING_PAYMENT: "MAKING_PAYMENT",
  MADE_PAYMENT: "MADE_PAYMENT",
  MAKE_PAYMENT_FAILED: "MAKE_PAYMENT_FAILED",

  ADDING_PAYMENT_METHOD: "ADDING_PAYMENT_METHOD",
  ADDED_PAYMENT_METHOD: "ADDED_PAYMENT_METHOD",
  ADD_PAYMENT_METHOD_FAILED: "ADD_PAYMENT_METHOD_FAILED",

  DELETING_PAYMENT_METHOD: "DELETING_PAYMENT_METHOD",
  DELETED_PAYMENT_METHOD: "DELETED_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD_FAILED: "DELETE_PAYMENT_METHOD_FAILED",

  FETCHING_TABLE_EXPORT: "FETCHING_TABLE_EXPORT",
  FETCHED_TABLE_EXPORT: "FETCHED_TABLE_EXPORT",
  FETCH_TABLE_EXPORT_FAILED: "FETCH_TABLE_EXPORT_FAILED",

  FETCHING_CURRENCIES: "FETCHING_CURRENCIES",
  FETCHED_CURRENCIES: "FETCHED_CURRENCIES",
  FETCH_CURRENCIES_FAILED: "FETCH_CURRENCIES_FAILED",
  SET_SELECTED_CURRENCY: "SET_SELECTED_CURRENCY",

  FETCHING_CURRENCY_STATS: "FETCHING_CURRENCY_STATS",
  FETCHED_CURRENCY_STATS: "FETCHED_CURRENCY_STATS",
  FETCH_CURRENCY_STATS_FAILED: "FETCH_CURRENCY_STATS_FAILED",

  FETCHING_STATEMENTS_SEARCH_RESULTS: "FETCHING_STATEMENTS_SEARCH_RESULTS",
  FETCHED_STATEMENTS_SEARCH_RESULTS: "FETCHED_STATEMENTS_SEARCH_RESULTS",
  FETCH_STATEMENTS_SEARCH_RESULTS_FAILED: "FETCH_STATEMENTS_SEARCH_RESULTS_FAILED",

  SET_OPEN_INVOICES_TO_SEARCH_RESULT: "SET_OPEN_INVOICES_TO_SEARCH_RESULT",
  SET_CLOSED_INVOICES_TO_SEARCH_RESULT: "SET_CLOSED_INVOICES_TO_SEARCH_RESULT",
  SET_PAYMENTS_TO_SEARCH_RESULT: "SET_PAYMENTS_TO_SEARCH_RESULT",

  POSTING_AUTO_PAY_CONFIG: "POSTING_AUTO_PAY_CONFIG",
  POSTED_AUTO_PAY_CONFIG: "POSTED_AUTO_PAY_CONFIG",
  FAILED_POST_AUTO_PAY_CONFIG: "FAILED_POST_AUTO_PAY_CONFIG",

  SET_SELECTED_KEYS: "SET_SELECTED_KEYS",

  CLEAR_STATEMENTS_SEARCH_RESULTS: "CLEAR_STATEMENTS_SEARCH_RESULTS"
};

const debouncedSearchResultsFetch = debounce(
  (endpoint, dispatch, searchTerm) =>
    data
      .get(endpoint)
      .then(res =>
        dispatch({
          type: actions.FETCHED_STATEMENTS_SEARCH_RESULTS,
          searchResults: res.data.value,
          searchTerm
        })
      )
      .catch(error => {
        dispatch({ type: actions.FETCH_STATEMENTS_SEARCH_RESULTS_FAILED });
        handlePromiseError(error, "Fetching search results failed.", "search results");
      }),
  400
);

const fetchStatementSearchResults = request => (dispatch, getState) => {
  const { companyId, perspectiveId, withCompanyId, fromDate, toDate, searchTerm, type = "all", allAccountsSelected } = request;

  if (isEmpty(searchTerm)) {
    return dispatch({ type: actions.CLEAR_STATEMENTS_SEARCH_RESULTS });
  }

  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/${type}`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }
  
 let filterQuery = `&$filter=Id%20LIKE%20${encodeURI(searchTerm)}`;
  if(type === 'open') {
    filterQuery = `&$filter=Id%20LIKE%20${encodeURI(searchTerm)}%20OR%20CustPONo%20LIKE%20${encodeURI(searchTerm)}`;
  }

  endpoint = `${endpoint}?$top=${getState().general.pageRowCount}${filterQuery}&$allAccountsSelected=${!!allAccountsSelected}`;

  dispatch({ type: actions.FETCHING_STATEMENTS_SEARCH_RESULTS, searchTerm });
  debouncedSearchResultsFetch(endpoint, dispatch, searchTerm);
};

const fetchAllInvoices = (companyId, perspectiveId, withCompanyId, options) => (dispatch, getState) => {
  const { fromDate = null, toDate = null, top, skip, filter } = options;
  const state = getState().statements;
  if (state.isFetchingOpenInvoices === true && state.withCompanyId === withCompanyId) {
    return;
  }
  dispatch({ type: actions.FETCHING_ALL_INVOICES, withCompanyId });
  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/all`;
  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }
  endpoint = `${endpoint}?$orderBy=${state.openInvoicesSortBy}%20${state.openInvoicesSortDirection}${state.openInvoicesSortBy === "Id" ? "" : ",%20Id%20desc"
    }`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}&$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  if (!isEmpty(filter)) {
    endpoint += `&$filter=${filter}`;
  }

  return data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_ALL_INVOICES,
        openInvoices: response.data.value,
        count: response.data.count,
        skip: skip || 0,
        totalAmount: response.data.totalAmount,
        nextLink: response.data.nextLink,
        withCompanyId
      });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "Fetching invoices failed.", "invoice information");
      dispatch({ type: actions.FETCH_ALL_INVOICES_FAILED });
    });
};

const fetchClosedInvoices = (
  companyId,
  perspectiveId,
  withCompanyId,
  fromDate = null,
  toDate = null,
  top,
  skip,
  filter,
  allAccountsSelected
) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.isFetchingClosedInvoices === true && state.withCompanyId === withCompanyId) {
    return;
  }

  dispatch({ type: actions.FETCHING_CLOSED_INVOICES, withCompanyId });
  let endpoint = `v3/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/closed`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}?$orderBy=${state.closedInvoicesSortBy}%20${state.closedInvoicesSortDirection}${state.closedInvoicesSortBy.toLowerCase() === "id" ? "" : ",%20Id%20desc"
    }`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}&$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  const currency = state.selectedCurrency;

  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}%20AND%20CurrencyID%20=%20${currency}`;
  } else {
    endpoint = `${endpoint}&$filter=CurrencyID%20=%20${currency}`;
  }

  endpoint = `${endpoint}&$allAccountsSelected=${!!allAccountsSelected}`;

  data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CLOSED_INVOICES,
        closedInvoices: response.data.value,
        count: response.data.count,
        skip: skip || 0,
        totalAmount: response.data.totalAmount,
        totalAmountCurrentYear: response.data.totalAmountCurrentYear,
        totalAmountPastYear: response.data.totalAmountPastYear,
        nextLink: response.data.nextLink,
        withCompanyId,
        currency,
        response: response.data,
        disallowPortalSendMessage: response.data.disallowPortalSendMessage
      });
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_CLOSED_INVOICES_FAILED });
      handlePromiseError(error, "Fetching closed invoices failed.", "invoice information");
    });
};


const fetchClosedInvoicesTemplate = (
  companyId,
  perspectiveId,
  withCompanyId,
  filters = { fromDate: null, toDate: null, invoiceIds: null }
) => (dispatch, getState) => {
  const { fromDate, toDate, invoiceIds } = filters;

  dispatch({ type: actions.FETCHING_CLOSED_INVOICES_TEMPLATE });
  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/closed`;
  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}/template`;

  if (Array.isArray(invoiceIds) && invoiceIds.length > 0) {
    endpoint = `${endpoint}?$filter=Id%20in%20${invoiceIds.join()}`;
  }

  return data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_CLOSED_INVOICES_TEMPLATE
      });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "Fetching closed invoices template failed.", "closed invoice template");
      dispatch({ type: actions.FETCH_CLOSED_INVOICES_TEMPLATE_FAILED });
      throw error;
    });
};

const setSelectedKeys = (keys, rows) => (dispatch) => {
  dispatch({ type: actions.SET_SELECTED_KEYS, keys, rows });
}

const fetchOpenInvoices = (companyId, perspectiveId, withCompanyId, fromDate, toDate = null, top, skip, selectAll, filter, allAccountsSelected) => (
  dispatch,
  getState
) => {
  const state = getState().statements;
  const optimizedPerformance = getState().accounts?.selectedAccount?.features?.optimizedPerformance;

  if (state.isFetchingOpenInvoices === true && state.withCompanyId === withCompanyId) {
    return;
  }

  dispatch({ type: actions.FETCHING_OPEN_INVOICES, withCompanyId });

  let endpoint = `v3/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/open`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;

    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}?$orderBy=${state.openInvoicesSortBy.toLowerCase()}%20${state.openInvoicesSortDirection}${state.openInvoicesSortBy.toLowerCase() === "id" || state.openInvoicesSortBy.toLowerCase() === "TranId".toLowerCase() ? "" : ",%20Id%20desc"
    }`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}&$top=${top}`;
  }

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  const currency = state.selectedCurrency;

  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}%20AND%20CurrencyID%20=%20${currency}`;
  } else {
    endpoint = `${endpoint}&$filter=CurrencyID%20=%20${currency}`;
  }

  endpoint = `${endpoint}&$allAccountsSelected=${!!allAccountsSelected}`;

  data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_OPEN_INVOICES,
        openInvoices: response.data.value,
        count: response.data.count,
        skip: skip || 0,
        totalBalance: response.data.totalBalance,
        pastDueCount: response.data.pastDueCount,
        totalPastDue: response.data.totalPastDue,
        nextLink: response.data.nextLink,
        withCompanyId,
        currency,
        response: response.data,
        selectAll,
        disallowPortalSendMessage: response.data.disallowPortalSendMessage
      });

      //for new api feature below line of codes will get execute, as we are fetching currency data as well
      if (optimizedPerformance) {
        let currencyObj = {};
        for (let currencyId in response.data?.currencyDetails) {
          currencyObj[currencyId] = { "count": response.data?.currencyDetails[currencyId] }
        }
        dispatch({
          type: actions.FETCHED_CURRENCY_STATS,
          openInvoicesCurrencyStats: currencyObj,
          closedInvoicesCurrencyStats: {},
          paymentsCurrencyStats: {}
        });
      }

    })
    .catch(error => {
      handlePromiseError(error, "Fetching open invoices failed.", "invoice information");
      dispatch({ type: actions.FETCH_OPEN_INVOICES_FAILED });
    });
};

const refreshOpenInvoices = () => (dispatch, getState) => {
  let state = getState();
  let {
    perspectives: { selectedPerspectiveId },
    statements: { companyId, withCompanyId, openInvoicesSkip },
    general: { pageRowCount }
  } = state;

  return dispatch(
    fetchOpenInvoices(companyId, selectedPerspectiveId, withCompanyId, null, null, pageRowCount, openInvoicesSkip)
  );
};

const fetchOpenInvoicesTemplate = (
  companyId,
  perspectiveId,
  withCompanyId,
  filters = { fromDate: null, toDate: null, invoiceIds: null }
) => (dispatch, getState) => {
  const { fromDate, toDate, invoiceIds } = filters;

  dispatch({ type: actions.FETCHING_OPEN_INVOICES_TEMPLATE });
  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/open`;
  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}/template`;

  if (Array.isArray(invoiceIds) && invoiceIds.length > 0) {
    endpoint = `${endpoint}?$filter=Id%20in%20${invoiceIds.join()}`;
  }

  return data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_OPEN_INVOICES_TEMPLATE
      });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "Fetching open invoices template failed.", "open invoice template");
      dispatch({ type: actions.FETCH_OPEN_INVOICES_TEMPLATE_FAILED });
      throw error;
    });
};

const fetchPayments = (companyId, perspectiveId, withCompanyId, fromDate = null, toDate = null, top, skip, filter, allAccountsSelected) => (
  dispatch,
  getState
) => {
  const state = getState().statements;

  if (state.isFetchingPayments === true && state.withCompanyId === withCompanyId) {
    return;
  }

  dispatch({ type: actions.FETCHING_PAYMENTS, withCompanyId });
  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/payments`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  endpoint = `${endpoint}?$orderBy=${state.paymentsSortBy.toLowerCase()}%20${state.paymentsSortDirection}${(state.paymentsSortBy.toLowerCase() === "id" || state.paymentsSortBy.toLowerCase() === "processeddate") ? "" : ",ProcessedDate%20desc%20,%20Id%20desc"
    }`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}&$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  const currency = state.selectedCurrency;

  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}%20AND%20CurrencyID%20=%20${currency}`;
  } else {
    endpoint = `${endpoint}&$filter=CurrencyID%20=%20${currency}`;
  }

  endpoint = `${endpoint}&$allAccountsSelected=${!!allAccountsSelected}`;

  data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_PAYMENTS,
        payments: response.data.value,
        count: response.data.count,
        skip: skip || 0,
        totalAmount: response.data.totalAmount,
        totalAmountCurrentYear: response.data.totalAmountCurrentYear,
        totalAmountPastYear: response.data.totalAmountPastYear,
        totalBalance: response.data.totalBalance,
        nextLink: response.data.nextLink,
        withCompanyId,
        currency,
        response: response.data
      });
    })
    .catch(error => {
      handlePromiseError(error, "Fetching payments failed.", "payment information");
      dispatch({ type: actions.FETCH_PAYMENTS_FAILED });
    });
};

const fetchUnappliedOrPendingPayments = (
  companyId,
  perspectiveId,
  withCompanyId,
  fromDate = null,
  toDate = null,
  top,
  skip,
  allAccountsSelected
) => (dispatch, getState) => {
  const state = getState().statements;

  if (state.isFetchingUnappliedOrPendingPayments === true && state.withCompanyId === withCompanyId) {
    return;
  }

  dispatch({ type: actions.FETCHING_UNAPPLIED_PAYMENTS, withCompanyId });
  let endpoint = `v3/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/payments`;

  if (!isEmpty(fromDate)) {
    endpoint = `${endpoint}/from/${fromDate}`;
    if (!isEmpty(toDate)) {
      endpoint = `${endpoint}/to/${toDate}`;
    }
  }

  const currency = state.selectedCurrency;

  endpoint = `${endpoint}?$filter=(UnappliedAmt > 0 OR PaymentName LIKE 'Pending Payment') AND CurrencyID = ${currency}`;
  endpoint = `${endpoint}&orderBy=${state.unappliedPaymentsSortBy.toLowerCase()}%20${state.unappliedPaymentsSortDirection}${state.unappliedPaymentsSortBy.toLowerCase() === "id" ? "" : ",%20Id%20desc"}`;
  endpoint = `${endpoint}&$top=${top || getState().general.pageRowCount}&$allAccountsSelected=${!!allAccountsSelected}`;

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.FETCHED_UNAPPLIED_PAYMENTS,
        unappliedOrPendingPayments: response.data.value,
        totalBalance: response.data.totalBalance,
        totalAmount: response.data.totalAmount,
        count: response.data.count,
        skip: skip || 0,
        withCompanyId
      });
    })
    .catch(error => {
      handlePromiseError(error, "Fetching payments failed.", "payment information");
      dispatch({ type: actions.FETCH_UNAPPLIED_PAYMENTS_FAILED });
    });
};

const validateAndUpdatePaymentInfo=({companyId,paymentInfo})=>{
  const getSessionStoragePaymentInfo = JSON.parse(sessionStorage.getItem("paymentInfo"));
  if (!getSessionStoragePaymentInfo || !getSessionStoragePaymentInfo.hasOwnProperty(companyId) || 
      !_.isEqual(getSessionStoragePaymentInfo[companyId], paymentInfo)) {
      sessionStorage.setItem("paymentInfo", JSON.stringify({...getSessionStoragePaymentInfo, [companyId]: paymentInfo }));
  }
}

const fetchPaymentInfo = (companyId, withCompanyId) => (dispatch) => {
  dispatch({ type: actions.FETCHING_PAYMENT_INFO });    
  return data
    .get(`v2/api/payments/from/${companyId}/to/${withCompanyId}/info`)
    .then(res => {        
       if(res && res.data) {
        const emptyPaymentMethod = {isDefault: false,name: "None",paymentMethodId : "None"};
        res.data.paymentMethods.push(emptyPaymentMethod);
       }    
      validateAndUpdatePaymentInfo({companyId,paymentInfo:res.data});
       dispatch({ type: actions.FETCHED_PAYMENT_INFO, paymentInfo: res.data, withCompanyId });
       return res;      
    })
    .catch(error => {
      handlePromiseError(error, Resources.PaymentInfoFetchError, "payment info");
      dispatch({ type: actions.FETCH_PAYMENT_INFO_FAILED });
      throw error;
    });
};

const postAutoPayConfig = (companyId, withCompanyId, config) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.postingAutoPayConfig) {
    return;
  }

  dispatch({ type: actions.POSTING_AUTO_PAY_CONFIG });

  return data
    .post(`v2/api/payments/from/${companyId}/to/${withCompanyId}/automaticpaymentconfiguration/save`, config)
    .then(response => {
      dispatch({ type: actions.POSTED_AUTO_PAY_CONFIG });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.AutoPayConfigurationError, "auto pay configuration");
      dispatch({ type: actions.FAILED_POST_AUTO_PAY_CONFIG });
      throw error;
    });
};

const makePayment = (companyId, withCompanyId, request, invoiceLength) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.isMakingPayment) {
    return;
  }

  dispatch({ type: actions.MAKING_PAYMENT });
  let endpoint = invoiceLength?'v2/api/payments/request/pay':`v2/api/payments/from/${companyId}/to/${withCompanyId}/payment`
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.MADE_PAYMENT });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.MakePaymentError, "payment");
      dispatch({ type: actions.MAKE_PAYMENT_FAILED });
      throw error;
    });
};

const addOrUpdatePaymentMethod = (companyId, withCompanyId, request) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.isAddingPaymentMethod) {
    return;
  }

  const dispatchAction = { type: actions.ADDING_PAYMENT_METHOD, withCompanyId };

  if (request.isDefault === true && !isEmpty(request.paymentMethodId)) {
    dispatchAction.setAsDefault = true;
    dispatchAction.paymentMethodId = request.paymentMethodId;
  }
  dispatch(dispatchAction);

  return data
    .post(`v2/api/payments/from/${companyId}/to/${withCompanyId}/info/methods`, request)
    .then(response => {
      dispatch({ type: actions.ADDED_PAYMENT_METHOD });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.ADD_PAYMENT_METHOD_FAILED });
      throw error;
    });    
};

const deletePaymentMethod = (companyId, withCompanyId, paymentMethodId, groupKey, customerKey) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.isAddingPaymentMethod) {
    return;
  }

  dispatch({ type: actions.DELETING_PAYMENT_METHOD });

  return data
    .delete(`v2/api/payments/from/${groupKey}/to/${customerKey}/methods/${paymentMethodId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_PAYMENT_METHOD, withCompanyId, paymentMethodId, message: response.data });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, Resources.DeletePaymentMethodError, "payment method");
      dispatch({ type: actions.DELETE_PAYMENT_METHOD_FAILED });
      throw error;
    });
};

const fetchTableExport = (companyId, perspectiveId, withCompanyId, tableName, top, skip = 0, onIds, options, allAccountsSelected) => (
  dispatch,
  getState
) => {
  const state = getState().statements;
  if (state.fetchingExportTable) {
    return;
  }
  dispatch({ type: actions.FETCHING_TABLE_EXPORT });

  let { fromDate, toDate, fromDueDate, toDueDate } = options;

  let sortBy;
  let sortDirection;

  switch (tableName) {
    case "open":
      sortBy = state.openInvoicesSortBy;
      sortDirection = state.openInvoicesSortDirection;
      break;
    case "closed":
      sortBy = state.closedInvoicesSortBy;
      sortDirection = state.closedInvoicesSortDirection;
      break;
    case "payments":
      sortBy = state.paymentsSortBy;
      sortDirection = state.paymentsSortDirection;
      break;
    default:
      break;
  }

  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/${tableName}`;

  if (!isEmpty(fromDueDate)) {
    endpoint = `${endpoint}/from/${fromDueDate}`;
    if (!isEmpty(toDueDate)) {
      endpoint = `${endpoint}/to/${toDueDate}`;
    }
  }
   
  endpoint = `${endpoint}/export?$top=${100000000}&$allAccountsSelected=${!!allAccountsSelected}`;

  let filter;
  if (!isEmpty(fromDate) && !isEmpty(toDate) && !isEmpty(fromDueDate) && !isEmpty(toDueDate)) {
    filter = `${tableName === "payments" ? "ProcessedDate" : "IssueDate"} >= ${fromDate} and ${tableName === "payments" ? "ProcessedDate" : "IssueDate"
      } <= ${toDate}`;
  }

  if (!isEmpty(onIds)) {
    if (isEmpty(filter)) {
      filter = `Id in ${onIds.map(id => id).join(",")}`;
    } else {
      filter += ` and Id in ${onIds.map(id => id).join(",")}`;
    }
  }

  if (!isEmpty(sortBy) && !isEmpty(sortDirection)) {
    endpoint += `&$orderBy=${sortBy}%20${sortDirection}${sortBy.toLowerCase() === "id" ||  sortBy.toLowerCase() === "TranId".toLowerCase() ? "" : ",%20Id%20desc"}`;
  }

  return data
    .post(endpoint, JSON.stringify(filter), {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    })
    .then(response => {
      dispatch({ type: actions.FETCHED_TABLE_EXPORT });
      return saveBlobAsDownload(response.data, `Export ${tableName} ${moment().format("Y-MM-DD--HH-mm-ss")}.xlsx`);
    })
    .catch(error => {
      handlePromiseError(error, Resources.ExportTableError, "payments");
      dispatch({ type: actions.FETCH_TABLE_EXPORT_FAILED });
      throw error;
    });
};

const fetchCurrencies = (companyId, perspectiveId, withCompanyId, allAccountsSelected) => (dispatch, getState) => {
  const state = getState().statements;
  const optimizedPerformance = getState().accounts?.selectedAccount?.features?.optimizedPerformance;

  if (state.fetchingCurrencies) {
    return;
  }
  dispatch({ type: actions.FETCHING_CURRENCIES });

  let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/currencies?$allAccountsSelected=${!!allAccountsSelected}`;

  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.FETCHED_CURRENCIES, currencies: response.data });

      //for old api feature fetchCurrency will get execute
      if (!optimizedPerformance) {
        dispatch(fetchCurrencyStats(companyId, perspectiveId, withCompanyId, response.data, allAccountsSelected));
      }
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetCurrenciesFailure, "currencies");
      dispatch({ type: actions.FETCH_CURRENCIES_FAILED });
      throw error;
    });
};

export const setSelectedCurrency = currency => dispatch => {
  return dispatch({ type: actions.SET_SELECTED_CURRENCY, currency });
};

const fetchCurrencyStats = (companyId, perspectiveId, withCompanyId, currencies, allAccountsSelected) => (dispatch, getState) => {
  const state = getState().statements;
  if (state.fetchingCurrencyStats) {
    return;
  }
  dispatch({ type: actions.FETCHING_CURRENCY_STATS });

  let openInvoicesCurrencyStats = Promise.all(
    currencies.map(currency => {
      let endpoint = `v3/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/open?$top=${1}&$filter=CurrencyID%20=%20${currency}&$totalsOnly=true&$allAccountsSelected=${!!allAccountsSelected}`;
      return data.get(endpoint).then(response => response.data);
    })
  ).then(response => {
    let currencyMap = {};
    response.forEach((item, i) => (currencyMap[currencies[i]] = item));
    return currencyMap;
  });

  let closedInvoicesCurrencyStats = Promise.all(
    currencies.map(currency => {
      let endpoint = `v3/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/closed?$top=${1}&$filter=CurrencyID%20=%20${currency}&$totalsOnly=true&$allAccountsSelected=${!!allAccountsSelected}`;
      return data.get(endpoint).then(response => response.data);
    })
  ).then(response => {
    let currencyMap = {};
    response.forEach((item, i) => (currencyMap[currencies[i]] = item));
    return currencyMap;
  });

  let paymentsInvoicesCurrencyStats = Promise.all(
    currencies.map(currency => {
      let endpoint = `v2/api/statement/${companyId}/${perspectiveId}/with/${withCompanyId}/payments?$top=${1}&$filter=CurrencyID%20=%20${currency}&$totalsOnly=true&$allAccountsSelected=${!!allAccountsSelected}`;
      return data.get(endpoint).then(response => response.data);
    })
  ).then(response => {
    let currencyMap = {};
    response.forEach((item, i) => (currencyMap[currencies[i]] = item));
    return currencyMap;
  });
  return Promise.all([openInvoicesCurrencyStats, closedInvoicesCurrencyStats, paymentsInvoicesCurrencyStats])
    .then(response => {
      dispatch({
        type: actions.FETCHED_CURRENCY_STATS,
        openInvoicesCurrencyStats: response[0],
        closedInvoicesCurrencyStats: response[1],
        paymentsCurrencyStats: response[2]
      });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetCurrenciesFailure, "currencies");
      dispatch({ type: actions.FETCH_CURRENCY_STATS_FAILED });
      throw error;
    });
};

const displayAddPaymentMethodFailed = () => (dispatch) =>
{
  dispatch({ type: actions.FETCH_PAYMENT_INFO_FAILED });
};

export const dispatchToProps = dispatch => ({
  setWithCompanyId: withCompanyId => {
    return dispatch({ type: actions.SET_WITH_COMPANY_ID, withCompanyId });
  },
  clearStatementsSearchResults: () => {
    dispatch({ type: actions.CLEAR_STATEMENTS_SEARCH_RESULTS });
  },
  fetchStatementSearchResults: request => {
    dispatch(fetchStatementSearchResults(request));
  },
  setOpenInvoicesToSearchResult: invoices => {
    dispatch({ type: actions.SET_OPEN_INVOICES_TO_SEARCH_RESULT, openInvoices: invoices });
  },
  setClosedInvoicesToSearchResult: invoices => {
    dispatch({ type: actions.SET_CLOSED_INVOICES_TO_SEARCH_RESULT, closedInvoices: invoices });
  },
  setPaymentsToSearchResult: invoices => {
    dispatch({ type: actions.SET_PAYMENTS_TO_SEARCH_RESULT, payments: invoices });
  },
  fetchAllInvoices: (companyId, perspectiveId, withCompanyId, options) => {
    return dispatch(fetchAllInvoices(companyId, perspectiveId, withCompanyId, options));
  },
  fetchClosedInvoices: (companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, filter, allAccountsSelected) => {
    dispatch(fetchClosedInvoices(companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, filter, allAccountsSelected));
  },
  fetchClosedInvoicesTemplate: (companyId, perspectiveId, withCompanyId, fromDate, toDate) => {
    return dispatch(fetchClosedInvoicesTemplate(companyId, perspectiveId, withCompanyId, fromDate, toDate));
  },
  updateClosedInvoicesSort: (sortBy, sortDirection) => {
    return dispatch({ type: actions.UPDATE_CLOSED_INVOICES_SORT, sortBy, sortDirection });
  },
  updateClosedInvoicesDate: (fromDate, toDate, dueByFromDate, dueByToDate) => {
    return dispatch({ type: actions.UPDATE_CLOSED_INVOICES_DATE, fromDate, toDate, dueByFromDate, dueByToDate });
  },
  fetchOpenInvoices: (companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, selectAll, filter, allAccountsSelected) => {
    dispatch(fetchOpenInvoices(companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, selectAll, filter, allAccountsSelected));
  },
  setSelectedKeys: (key, row) => {
    dispatch(setSelectedKeys(key, row));
  },
  refreshOpenInvoices: () => {
    dispatch(refreshOpenInvoices());
  },
  fetchOpenInvoicesTemplate: (companyId, perspectiveId, withCompanyId, fromDate, toDate) => {
    return dispatch(fetchOpenInvoicesTemplate(companyId, perspectiveId, withCompanyId, fromDate, toDate));
  },
  updateOpenInvoicesSort: (sortBy, sortDirection) => {
    return dispatch({ type: actions.UPDATE_OPEN_INVOICES_SORT, sortBy, sortDirection });
  },
  updateOpenInvoicesDate: (fromDate, toDate, dueByFromDate, dueByToDate) => {
    return dispatch({ type: actions.UPDATE_OPEN_INVOICES_DATE, fromDate, toDate, dueByFromDate, dueByToDate });
  },
  fetchPaymentInfo: (companyId, withCompanyId) => {
    return dispatch(fetchPaymentInfo(companyId, withCompanyId));
  },
  postAutoPayConfig: (companyId, withCompanyId, config) => {
    return dispatch(postAutoPayConfig(companyId, withCompanyId, config));
  },
  fetchPayments: (companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, filter, allAccountsSelected) => {
    dispatch(fetchPayments(companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, filter, allAccountsSelected));
  },
  fetchUnappliedOrPendingPayments: (companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, allAccountsSelected) => {
    dispatch(fetchUnappliedOrPendingPayments(companyId, perspectiveId, withCompanyId, fromDate, toDate, top, skip, allAccountsSelected));
  },
  updatePaymentsSort: (sortBy, sortDirection) => {
    return dispatch({ type: actions.UPDATE_PAYMENTS_SORT, sortBy, sortDirection });
  },
  updatePaymentsDate: (fromDate, toDate) => {
    return dispatch({ type: actions.UPDATE_PAYMENTS_DATE, fromDate, toDate });
  },
  updateUnappliedPaymentsSort: (sortBy, sortDirection) => {
    return dispatch({ type: actions.UPDATE_UNAPPLIED_PAYMENTS_SORT, sortBy, sortDirection });
  },
  makePayment: (companyId, withCompanyId, request, invoiceLength) => {
    return dispatch(makePayment(companyId, withCompanyId, request, invoiceLength));
  },
  addOrUpdatePaymentMethod: (companyId, withCompanyId, request) => {
    return dispatch(addOrUpdatePaymentMethod(companyId, withCompanyId, request));
  },
  deletePaymentMethod: (companyId, withCompanyId, paymentMethodId, groupKey, customerKey) => {
    return dispatch(deletePaymentMethod(companyId, withCompanyId, paymentMethodId, groupKey, customerKey));
  },
  fetchTableExport: (companyId, perspectiveId, withCompanyId, tableName, top, skip, onIds, options, allAccountsSelected) => {
    return dispatch(fetchTableExport(companyId, perspectiveId, withCompanyId, tableName, top, skip, onIds, options, allAccountsSelected));
  },
  fetchCurrencies: (companyId, perspectiveId, withCompanyId, allAccountsSelected) => {
    return dispatch(fetchCurrencies(companyId, perspectiveId, withCompanyId, allAccountsSelected));
  },
  setSelectedCurrency: currency => {
    return dispatch(setSelectedCurrency(currency));
  },
  displayAddPaymentMethodFailed:() =>
  {
    return dispatch(displayAddPaymentMethodFailed()); 
  },
});
